import { format } from "date-fns/format";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import {
  Flex,
  HStack,
  Stack,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Address } from "viem";
import { Avatar, Button, LazyLoad, Text, UsernameDisplay, currencyTokenIconMap } from "@looksrare/uikit";
import { formatNumberToLocale, isAddressEqual, useGetCurrencyConfig } from "@looksrare/utils";
import { QuantumGameFragment } from "@looksrare/yolo-games-gql-typegen";
import { transformIntToDecimalString } from "../../../utils/general";
import { quantumInputMaxDecimals } from "../../../config/settings";
import { getChainId, getFormattedAmount } from "../../../../shared/utils";
import { TransactionHashMenu } from "../../../../shared/components";
import { ConnectWalletPrompt, EmptyHistoryTable } from "../../../../shared/components";
import { ClassicGamesHistorySort } from "../../../../shared/types";

const hideOnMobile = {
  display: { base: "none", lg: "table-cell" },
};

interface QuantumHistoryTableProps {
  games: QuantumGameFragment[];
  hasNextPage: boolean;
  isHistoryQuerySuccess: boolean;
  sort: ClassicGamesHistorySort;
  onClickLoadMore: () => void;
}

const ThText = ({ children, ...props }: TableCellProps) => {
  return (
    <Th {...props}>
      <Text textStyle="helper" color="text-03" bold>
        {children}
      </Text>
    </Th>
  );
};

export const QuantumHistoryTable = ({
  games,
  hasNextPage,
  isHistoryQuerySuccess = false,
  sort,
  onClickLoadMore,
}: QuantumHistoryTableProps) => {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const chainId = getChainId();

  const getCurrencyConfig = useGetCurrencyConfig(chainId);

  return (
    <TableContainer backgroundColor="ui-01" border="1px solid" borderColor="border-01" borderRadius="container">
      <Table>
        <Thead>
          <Tr>
            <ThText>{t("quantum::Player")}</ThText>
            <ThText {...hideOnMobile}>{t("quantum::Selection")}</ThText>
            <ThText textAlign="right">{t("quantum::Rolls")}</ThText>
            <ThText textAlign="right">{t("quantum::Entry")}</ThText>
            <ThText textAlign="right">{t("quantum::Payout")}</ThText>
            <ThText textAlign="right" {...hideOnMobile}>
              {t("quantum::Pts")}
            </ThText>
            <ThText textAlign="right" {...hideOnMobile}>
              {t("quantum::Date")}
            </ThText>
            <ThText textAlign="right">{t("quantum::Verify")}</ThText>
          </Tr>
        </Thead>

        <Tbody>
          {games.map(
            ({
              id,
              isAbove,
              boundary,
              playAmountWei,
              winningAmountWei,
              numberOfRoundsPlayed,
              createdAt,
              user,
              currency: currencyAddress,
              points,
              drawnTransactionHash,
              drawingTransactionHash,
            }) => {
              const isCurrentUser = isAddressEqual(user.address, address);
              const boundaryFloat = Number(transformIntToDecimalString(boundary));
              const isWin = !!(winningAmountWei && BigInt(winningAmountWei) > BigInt(playAmountWei));
              const { symbol } = getCurrencyConfig(currencyAddress as Address);
              const Icon = currencyTokenIconMap[symbol];

              return (
                <LazyLoad
                  as={Tr}
                  key={id}
                  borderLeft={isCurrentUser ? "4px solid" : "none"}
                  borderLeftColor="interactive-03"
                  transition="background-color 100ms"
                  _hover={{
                    bg: "border-01",
                  }}
                  _notLast={{ borderBottom: "1px solid", borderBottomColor: "border-01" }}
                >
                  <Td>
                    <HStack spacing={2}>
                      <Avatar src={user.avatar?.image?.src} address={user.address} size={24} />

                      {isCurrentUser ? (
                        <Text textStyle="detail" color="link-01" bold>
                          {t("quantum::You")}
                        </Text>
                      ) : (
                        <UsernameDisplay
                          address={user.address}
                          name={user.name}
                          textStyle="detail"
                          color={isCurrentUser ? "link-01" : "text-01"}
                          bold
                        />
                      )}
                    </HStack>
                  </Td>
                  <Td {...hideOnMobile}>
                    <Text textStyle="detail" color="text-03">
                      {isAbove ? t("quantum::Over") : t("quantum::Under")} {formatNumberToLocale(boundaryFloat, 0)}
                    </Text>
                  </Td>
                  <Td>
                    <Text textStyle="detail" color="text-03" textAlign="end">
                      {formatNumberToLocale(numberOfRoundsPlayed ?? 1, 0)}
                    </Text>
                  </Td>
                  <Td>
                    <HStack justifyContent="end" spacing={1}>
                      <Text textStyle="detail" color="text-01">
                        {getFormattedAmount(playAmountWei, {
                          largeNumberDecimals: 3,
                          smallNumberDecimals: quantumInputMaxDecimals + 1,
                          largeNumberTreshold: 0.1,
                        })}
                      </Text>
                      <Icon boxSize={4} />
                    </HStack>
                  </Td>
                  <Td>
                    <HStack justifyContent="end" spacing={1}>
                      <Text textStyle="detail" color={isWin ? "link-01" : "text-03"}>
                        {getFormattedAmount(winningAmountWei, {
                          largeNumberDecimals: 3,
                          smallNumberDecimals: quantumInputMaxDecimals + 1,
                          largeNumberTreshold: 0.1,
                        })}
                      </Text>
                      <Icon boxSize={4} />
                    </HStack>
                  </Td>
                  <Td {...hideOnMobile}>
                    <Text textStyle="detail" color="text-03" textAlign="end">
                      {getFormattedAmount(points, { fromDecimalsOptions: { decimals: 0 } })}
                    </Text>
                  </Td>
                  <Td {...hideOnMobile}>
                    <Stack spacing={0} alignItems="end">
                      <Text textStyle="helper" color="text-03">
                        {format(createdAt, "HH:mm")}
                      </Text>
                      <Text textStyle="helper" color="text-03">
                        {format(createdAt, "dd/MM/yyyy")}
                      </Text>
                    </Stack>
                  </Td>
                  <Td textAlign="end">
                    <TransactionHashMenu
                      drawnTransactionHash={drawnTransactionHash}
                      drawingTransactionHash={drawingTransactionHash}
                      chainId={getChainId()}
                    />
                  </Td>
                </LazyLoad>
              );
            }
          )}
        </Tbody>
      </Table>
      {(() => {
        if (!isConnected && sort === ClassicGamesHistorySort.MINE) {
          return (
            <Flex width="100%" justifyContent="center">
              <ConnectWalletPrompt py={12} px={4} width="100%" />
            </Flex>
          );
        }
        if (isConnected && isHistoryQuerySuccess && games.length === 0) {
          return (
            <Flex width="100%" justifyContent="center">
              <EmptyHistoryTable py={12} px={4} width="100%" />
            </Flex>
          );
        }
        if (!!hasNextPage) {
          return (
            <VStack px={4} py={3} borderTop="1px solid" borderTopColor="border-01">
              <Button onClick={onClickLoadMore} variant="outline" colorScheme="secondary">
                {t("quantum::Load More")}
              </Button>
            </VStack>
          );
        }
      })()}
    </TableContainer>
  );
};

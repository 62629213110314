import { Divider, HStack, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react";
import { Accordion, AccordionIcon, NumberInput, Text, currencyTokenIconMap } from "@looksrare/uikit";
import { type Currency } from "@looksrare/config";
import { useTranslation } from "next-i18next";

interface QuantumEntryAdvancedProps {
  stopOnProfitAmount: string;
  setStopOnProfitAmount: (value: string) => void;
  stopOnLossAmount: string;
  setStopOnLossAmount: (value: string) => void;
  currentCurrency: Currency;
}

export const QuantumEntryAdvanced = ({
  stopOnProfitAmount,
  setStopOnProfitAmount,
  stopOnLossAmount,
  setStopOnLossAmount,
  currentCurrency,
}: QuantumEntryAdvancedProps) => {
  const { t } = useTranslation();

  const CurrencyIcon = currencyTokenIconMap[currentCurrency];

  return (
    <Accordion
      background="none"
      buttonProps={{
        p: 0,
        border: "none",
      }}
      accordionItemProps={{
        border: "none",
      }}
      panelProps={{
        p: 0,
        pt: 4,
      }}
      leftButtonElementWrapperProps={{
        width: "100%",
      }}
      leftButtonElement={
        <HStack spacing={1} width="100%">
          <Text textStyle="detail" fontWeight="normal">
            {t("quantum::Advanced")}
          </Text>
          <AccordionIcon />
          <Divider />
        </HStack>
      }
      showAccordionIcon={false}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <Text textStyle="detail" color="text-03" bold>
            {t("quantum::Stop on Profit")}
          </Text>

          <InputGroup>
            <InputLeftElement height="48px">
              <CurrencyIcon boxSize={5} />
            </InputLeftElement>

            <NumberInput value={stopOnProfitAmount} onTextChange={setStopOnProfitAmount} height="48px" pl={10} />
          </InputGroup>
        </Stack>

        <Stack spacing={1}>
          <Text textStyle="detail" color="text-03" bold>
            {t("quantum::Stop on Loss")}
          </Text>

          <InputGroup>
            <InputLeftElement height="48px">
              <CurrencyIcon boxSize={5} />
            </InputLeftElement>

            <NumberInput value={stopOnLossAmount} onTextChange={setStopOnLossAmount} height="48px" pl={10} />
          </InputGroup>
        </Stack>
      </Stack>
    </Accordion>
  );
};

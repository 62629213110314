import { useEffect } from "react";
import { diceTransitionDurationMs, preEmptResultSoundMs } from "../../config/settings";
import { useQuantumConfig } from "../../hooks/useQuantumConfig";

export const useQuantumDiceSound = (value: string, isWin: boolean) => {
  const { isMuted } = useQuantumConfig();

  useEffect(() => {
    if (!isMuted) {
      const rollSound = new Audio("/sounds/quantum/single_roll.mp3");
      const winSound = new Audio("/sounds/quantum/win_roll.mp3");
      const loseSound = new Audio("/sounds/quantum/lose_roll.mp3");

      rollSound.play();

      setTimeout(() => {
        if (isWin) {
          winSound.play();
        } else {
          loseSound.play();
        }
      }, diceTransitionDurationMs - preEmptResultSoundMs);

      return () => {
        rollSound.remove();
        winSound.remove();
        loseSound.remove();
      };
    }
  }, [value, isWin, isMuted]);
};

import { INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import { AutoTransactionStepRow, TransactionSetter, useHandleModalStep } from "@looksrare/uikit";
import { NoPublicClientError, sleep, toDecimals, useAddressesByNetwork } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { usePublicClient } from "wagmi";
import { usePlayQuantum } from "../../../../network/contract/write/hooks";
import { useQuantumStore } from "../../../../stores/QuantumStore";
import { getQuantumMultiplierAsBigint } from "../../../../utils/multiplierUtils";
import { useGetVrfParams } from "../../../../../shared/network/contract/read/hooks";

interface QuantumEntryTransactionRowProps {
  isActive: boolean;
  onSuccess?: () => void;
}

export const QuantumEntryTransactionRow = ({ isActive, onSuccess }: QuantumEntryTransactionRowProps) => {
  const { t } = useTranslation();
  const publicClient = usePublicClient();

  const play = usePlayQuantum();
  const getVrfParams = useGetVrfParams();

  const {
    entryAmount,
    rollsAmount,
    cutOff,
    isAbove,
    stopOnProfitAmount,
    stopOnLossAmount,
    currentCurrency,
    setDrawingGameTransactionHash,
  } = useQuantumStore();

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new NoPublicClientError();
        }

        const vrfParams = await getVrfParams();

        const hash = await play({
          numberOfRounds: Number(rollsAmount),
          playAmountPerRound: toDecimals(entryAmount),
          currency: currentCurrency,
          stopGain: toDecimals(stopOnProfitAmount),
          stopLoss: -toDecimals(stopOnLossAmount), // Contract expects negative number
          isAbove,
          multiplier: getQuantumMultiplierAsBigint(cutOff, isAbove),
          vrfFee: vrfParams.vrfFee,
        });

        setTransaction(hash);
        setDrawingGameTransactionHash(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          // Wait for the transaction to be indexed
          await sleep(INDEXER_REFETCH_DELAY_MS);

          setTransaction(undefined);
        } else {
          throw new Error(`Entry failed. Transaction hash ${receipt.transactionHash}`);
        }
      },
      onSuccess: async () => {
        onSuccess?.();
      },
    });
  };

  return (
    <AutoTransactionStepRow
      ctaText={t("quantum::Send Funds")}
      useHandleTransaction={useHandleTransaction}
      isStepActive={isActive}
    />
  );
};

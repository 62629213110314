import { Box, BoxProps, Grid } from "@chakra-ui/react";
import padStart from "lodash/padStart";
import { Text } from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";
import { diceTransitionDurationMs } from "../../config/settings";
import { getDecimalResult } from "../../utils/general";
import { useQuantumDiceSound } from "./useQuantumDiceSound";

// A circle has 360 degrees, divided by 10 digits = 36 degrees per digit.
// Being displayed as a square, only 1/4th of these degrees are unique, reducing it to 9 degrees per digit.
// To enhance the animation, we multiply by an arbitrary 7, resulting in 63 degrees of rotation per digit.
const degreesPerDigit = 63;

export const quantumDiceSize = { base: "120px", md: "136px" };

interface QuantumDiceProps extends BoxProps {
  value: string;
  isWin: boolean;
  isAbove: boolean;
}

export const QuantumDice = ({ value, isWin, isAbove, ...props }: QuantumDiceProps) => {
  useQuantumDiceSound(value, isWin);

  const valueNumber = getDecimalResult({ value: Number(value), isWin, isAbove });
  const formattedValue = formatNumberToLocale(valueNumber, 2);
  const displayValue = padStart(formattedValue, 5, "0");

  const digits = displayValue.replace(/[.,]/g, "").split("");

  const borderColor = isWin ? "support-success" : "support-error";

  return (
    <Box p={5} boxSize={quantumDiceSize} {...props}>
      <Grid position="relative" placeItems="center" boxSize="100%">
        {digits.map((digit, index) => {
          const valueRotation = Number(digit) * degreesPerDigit;

          return (
            <Box
              key={index}
              position="absolute"
              inset={0}
              borderWidth="1px"
              borderColor={borderColor}
              transform={`rotate(${valueRotation}deg)`}
              transition={`transform ${diceTransitionDurationMs}ms, border-color ${diceTransitionDurationMs}ms`}
            />
          );
        })}

        <Text textStyle={{ base: "detail", md: "display-body" }} color="text-01" bold>
          {displayValue}
        </Text>
      </Grid>
    </Box>
  );
};

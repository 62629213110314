import { IconButton, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { CloseIcon, NumberInput, PercentIcon, RenewRefreshIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatNumberToLocale } from "@looksrare/utils";
import { useQuantumStore } from "../../stores/QuantumStore";
import {
  quantumMaxAboveCutOff,
  quantumMaxBelowCutOff,
  quantumMaxChance,
  quantumMaxMultiplier,
  quantumMinAboveCutOff,
  quantumMinBelowCutOff,
  quantumMinChance,
  quantumMinMultiplier,
} from "../../config/settings";

export const QuantumChanceInput = () => {
  const { t } = useTranslation();
  const {
    inputCutOff,
    inputMultiplier,
    inputChance,
    cutOff,
    multiplier,
    chance,
    isAbove,
    setMultiplier,
    setCutOff,
    switchIsAbove,
    setChance,
  } = useQuantumStore();

  const multiplierFloat = multiplier ? Number(multiplier) : 0;
  const cutOffFloat = cutOff ? Number(cutOff) : 0;
  const chanceFloat = chance ? Number(chance) : 0;

  const minCutOff = isAbove ? quantumMinAboveCutOff : quantumMinBelowCutOff;
  const maxCutOff = isAbove ? quantumMaxAboveCutOff : quantumMaxBelowCutOff;

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      spacing={4}
      alignItems="center"
      p={6}
      bgGradient="linear-gradient(to bottom, rgba(31, 42, 55, 0.00) 0%, #111927 100%)"
    >
      <Stack direction={{ base: "row", md: "column", lg: "row" }} spacing={4} alignItems="center" flex={2} width="100%">
        <Stack spacing={1} width="100%">
          <Text textStyle="detail" color="text-03" bold>
            {t("quantum::Multiplier")}
          </Text>

          <InputGroup size="md">
            <NumberInput value={inputMultiplier} onTextChange={setMultiplier} pr={10} />

            <InputRightElement pr={1}>
              <CloseIcon boxSize={5} />
            </InputRightElement>
          </InputGroup>

          {multiplierFloat < quantumMinMultiplier && (
            <Text textStyle="helper" color="text-error">
              {t("quantum::Minimum multiplier: {{multiplier}}", {
                multiplier: formatNumberToLocale(quantumMinMultiplier, 0, 4),
              })}
            </Text>
          )}

          {multiplierFloat > quantumMaxMultiplier && (
            <Text textStyle="helper" color="text-error">
              {t("quantum::Maximum multiplier: {{multiplier}}", {
                multiplier: formatNumberToLocale(quantumMaxMultiplier, 0, 4),
              })}
            </Text>
          )}
        </Stack>

        <Stack spacing={1} width="100%">
          <Text textStyle="detail" color="text-03" bold>
            {t("quantum::Roll {{underOrOver}}", { underOrOver: isAbove ? t("quantum::Over") : t("quantum::Under") })}
          </Text>

          <InputGroup size="md">
            <NumberInput value={inputCutOff} onTextChange={setCutOff} pr={10} />

            <InputRightElement pr={1}>
              <IconButton
                icon={<RenewRefreshIcon boxSize={5} />}
                onClick={switchIsAbove}
                aria-label={t("quantum::Toggle between rolling under or over")}
                size="sm"
                variant="solid"
                colorScheme="secondary"
              />
            </InputRightElement>
          </InputGroup>

          {cutOffFloat < minCutOff && (
            <Text textStyle="helper" color="text-error">
              {t("quantum::Minimum boundary: {{boundary}}", {
                boundary: formatNumberToLocale(minCutOff, 0),
              })}
            </Text>
          )}

          {cutOffFloat > maxCutOff && (
            <Text textStyle="helper" color="text-error">
              {t("quantum::Maximum boundary: {{boundary}}", {
                boundary: formatNumberToLocale(maxCutOff, 0),
              })}
            </Text>
          )}
        </Stack>
      </Stack>

      <Stack spacing={1} flex={1} width="100%">
        <Text textStyle="detail" color="text-03" bold>
          {t("quantum::Win Chance")}
        </Text>

        <InputGroup size="md">
          <NumberInput value={inputChance} onTextChange={setChance} pr={10} />

          <InputRightElement pr={1}>
            <PercentIcon boxSize={5} />
          </InputRightElement>
        </InputGroup>

        {chanceFloat < quantumMinChance && (
          <Text textStyle="helper" color="text-error">
            {t("quantum::Minimum chance: {{chance}}", {
              chance: formatNumberToLocale(quantumMinChance, 0),
            })}
          </Text>
        )}

        {chanceFloat > quantumMaxChance && (
          <Text textStyle="helper" color="text-error">
            {t("quantum::Maximum chance: {{chance}}", {
              chance: formatNumberToLocale(quantumMaxChance, 0),
            })}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

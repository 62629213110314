import { chakra, Flex, FlexProps, HStack, keyframes, VStack } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { Button, currencyTokenIconMap, Text } from "@looksrare/uikit";
import { BigIntish, formatToSignificant } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { quantumInputMaxDecimals } from "../../config/settings";

const titleTextShadow = "0px 0px 10px rgba(255, 255, 255, 0.50)";

export const blink = keyframes({
  "0%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0.5,
  },
});

interface QuantumGameTextProps extends FlexProps {
  currencySymbol: Currency;
  isRolling: boolean;
  isGenerating: boolean;
  isCancelled: boolean;
  isWin: boolean;
  isProcessingRefund: boolean;
  wonAmountWei?: BigIntish | null;
  onRefundClick?: () => void;
}

export const QuantumGameText = ({
  currencySymbol,
  isRolling,
  isGenerating,
  isCancelled,
  isWin,
  isProcessingRefund,
  wonAmountWei,
  onRefundClick,
  ...props
}: QuantumGameTextProps) => {
  const { t } = useTranslation();

  const Icon = currencyTokenIconMap[currencySymbol];

  return (
    <Flex justifyContent="center" {...props}>
      {(() => {
        if (isCancelled) {
          return (
            <VStack>
              <Text textStyle="display-03" textAlign="center" textShadow={titleTextShadow} bold>
                {t("quantum::Round Cancelled")}
              </Text>
              <Text textStyle="detail" textAlign="center">
                {t(
                  "quantum::Our source of on-chain randomness failed to return a result, so the round couldn't be completed."
                )}
              </Text>
              <Text textStyle="detail" textAlign="center">
                {t("quantum::Claim your refund to keep playing.")}
              </Text>
              <Button onClick={onRefundClick} width="100%" maxWidth="384px" isLoading={isProcessingRefund}>
                {t("quantum::Refund")}
              </Button>
            </VStack>
          );
        }

        if (isRolling) {
          return null;
        }

        if (isGenerating) {
          return (
            <Text textStyle="detail" color="link-01" animation={`${blink} 2s infinite`} bold>
              {t("quantum::Generating randomness...")}
            </Text>
          );
        }

        if (wonAmountWei && isWin) {
          // Bit of a weird setup, but this allows for more reliable wrapping of the text
          return (
            <HStack justifyContent="center" flexWrap="wrap">
              <Text textStyle="display-03" textAlign="center" textShadow={titleTextShadow} bold>
                {t("quantum::You won")}
              </Text>

              <Text textStyle="display-03" textAlign="center" textShadow={titleTextShadow} bold>
                <chakra.span color="link-01">
                  <Icon
                    boxSize="27px"
                    filter="drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.50))"
                    transform="translateY(-2px)"
                    mr={1}
                  />

                  {formatToSignificant(wonAmountWei, quantumInputMaxDecimals + 1)}
                </chakra.span>
                !
              </Text>
            </HStack>
          );
        }

        if (wonAmountWei && !isWin) {
          return (
            <Text textStyle="display-03" textAlign="center" textShadow={titleTextShadow} bold>
              {t("quantum::Unlucky")}
            </Text>
          );
        }
      })()}
    </Flex>
  );
};

import { rollsValueDecimals } from "../config/constants";

export const isWinningRoll = (roll: number, isAbove: boolean, boundary: number) => {
  if (isAbove) {
    return roll > boundary;
  }

  return roll < boundary;
};

// We get a rolls result as, for example, 250000 which would equal to 2.5 as float, so this converts that
export const transformIntToDecimalString = (int: number) => {
  return (int / rollsValueDecimals).toString();
};

/**
 * We want to make sure we don't get weird results because of rounding.
 * So, if the user won, we round up, if they lost, we round down.
 * (and the other way around if if user is betting on below)
 */
export const getDecimalResult = ({ value, isWin, isAbove }: { value: number; isWin: boolean; isAbove: boolean }) => {
  if (isWin || (!isWin && !isAbove)) {
    return Math.ceil(value * 100) / 100;
  }

  return Math.floor(value * 100) / 100;
};

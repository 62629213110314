import { useCallback, useEffect, useState } from "react";
import { isHash, isHashEqual, sleep, useGetCurrencyConfig } from "@looksrare/utils";
import { Currency } from "@looksrare/config";
import { Address } from "viem";
import { QuantumGameFragment } from "@looksrare/yolo-games-gql-typegen";
import { QuantumRoll } from "../types/general";
import { isWinningRoll, transformIntToDecimalString } from "../utils/general";
import { timeBetweenRollsMs } from "../config/settings";
import { getChainId } from "../../shared/utils";
import { useQuantumStore } from "../stores/QuantumStore";

export const useQuantumRolls = ({
  latestGame,
  onRollEnd,
}: {
  latestGame?: QuantumGameFragment | null;
  onRollEnd?: (isWin: boolean) => void;
}) => {
  const [isRolling, setIsRolling] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [rolls, setRolls] = useState<QuantumRoll[]>([]);
  const [wonAmountWei, setWonAmountWei] = useState<bigint | null>(null);
  const [isWin, setIsWin] = useState<boolean>(false);
  const [wonCurrencySymbol, setWonCurrencySymbol] = useState<Currency>("ETH");

  const getCurrencyConfig = useGetCurrencyConfig(getChainId());

  const { drawingGameTransactionHash, setDrawingGameTransactionHash } = useQuantumStore();

  useEffect(() => {
    if (
      latestGame &&
      latestGame.status === "DRAWN" &&
      isHash(drawingGameTransactionHash) &&
      isHash(latestGame.drawingTransactionHash) &&
      isHashEqual(drawingGameTransactionHash, latestGame.drawingTransactionHash)
    ) {
      // We don't reset the rolls array as we want to keep it going as long as the page is open
      setWonAmountWei(null);
      setIsGenerating(false);
      setIsRolling(true);

      setDrawingGameTransactionHash(null);

      // We will only show results of rounds that were played
      const results = latestGame.results
        ? latestGame.results.slice(0, latestGame.numberOfRoundsPlayed ?? undefined)
        : [];

      results.forEach(async (roll, index) => {
        await sleep(timeBetweenRollsMs * index);

        setRolls((prev) => [
          ...prev,
          {
            value: transformIntToDecimalString(roll),
            isWin: isWinningRoll(roll, latestGame.isAbove, latestGame.boundary),
            isAbove: latestGame.isAbove,
          },
        ]);

        if (index === results.length - 1) {
          const wonCurrencyAddress = latestGame.currency;
          const { symbol } = getCurrencyConfig(wonCurrencyAddress as Address);

          const grossWonAmountWei = BigInt(latestGame.winningAmountWei ?? 0n);
          const playAmountWei = BigInt(latestGame.playAmountWei);
          const netWonAmountWei = grossWonAmountWei - playAmountWei;
          const _isWin = netWonAmountWei > 0n;

          setIsRolling(false);
          setWonAmountWei(grossWonAmountWei);
          setIsWin(_isWin);
          setWonCurrencySymbol(symbol);

          onRollEnd?.(_isWin);
        }
      });
    }
  }, [drawingGameTransactionHash, getCurrencyConfig, latestGame, onRollEnd, setDrawingGameTransactionHash]);

  const onEntry = useCallback(() => {
    setIsGenerating(true);
  }, []);

  return { rolls, wonAmountWei, isWin, wonCurrencySymbol, isRolling, isGenerating, onEntry };
};

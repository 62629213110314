import { Context, useContext } from "react";
import { GameContext, GameProviderContext } from "@looksrare/uikit/components/GameProvider";
import { QuantumConfig } from "../types/general";

export const useQuantumConfig = () => {
  const context = useContext(GameProviderContext as Context<GameContext<QuantumConfig> | undefined>);
  if (!context?.config) {
    throw new Error("No config found");
  }

  return context.config;
};

import { Spinner, Stack, StackProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { Button } from "@looksrare/uikit";
import { fromDecimals, toDecimals, useAddressesByNetwork, useEthBalance, useTokenBalance } from "@looksrare/utils";
import { useQuantumStore } from "../../../../stores/QuantumStore";
import { getQuantumMultiplierAsBigint } from "../../../../utils/multiplierUtils";
import { useQuantumMinMaxPlayAmount } from "../../../../network/contract/read/hooks";
import { useQuantumConfig } from "../../../../hooks/useQuantumConfig";
import { quantumMinPlayAmount } from "../../../../config/settings";
import { PlayCTAWrapper } from "../../../../../shared/components/PlayCTAWrapper";
import { QuantumEntryEthAmount } from "./QuantumEntryEthAmount";
import { QuantumEntryRollsAmount } from "./QuantumEntryRollsAmount";
import { QuantumEntryAdvanced } from "./QuantumEntryAdvanced";

const gasBuffer = 0.0001; // Buffer for paying fees

interface QuantumEntryMainViewProps extends StackProps {
  onPlayClick: () => void;
  isRolling?: boolean;
  isGenerating?: boolean;
  isDisabled?: boolean;
}

export const QuantumEntryMainView = ({
  onPlayClick,
  isRolling,
  isGenerating,
  isDisabled,
  ...props
}: QuantumEntryMainViewProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const addresses = useAddressesByNetwork();

  const { isWalletBlocked } = useQuantumConfig();

  const {
    cutOff,
    isAbove,
    entryAmount,
    rollsAmount,
    stopOnProfitAmount,
    stopOnLossAmount,
    getIsInputValid,
    currentCurrency,
    setEntryAmount,
    setRollsAmount,
    setStopOnProfitAmount,
    setStopOnLossAmount,
    setCurrentCurrency,
  } = useQuantumStore();

  const currencyAddress = addresses[currentCurrency];

  const {
    data: [minPlayAmountWei, maxPlayAmountWei],
  } = useQuantumMinMaxPlayAmount({
    currencyAddress,
    multiplier: getQuantumMultiplierAsBigint(cutOff, isAbove),
  });

  let minPlayAmount = fromDecimals(minPlayAmountWei);
  const maxPlayAmount = fromDecimals(maxPlayAmountWei);

  if (Number(minPlayAmount) < quantumMinPlayAmount) {
    minPlayAmount = quantumMinPlayAmount.toString();
  }

  const isEth = currentCurrency === "ETH";
  const { data: ethBalanceWei } = useEthBalance(address!, { enabled: !!address && isEth });
  const { data: tokenBalanceWei } = useTokenBalance({
    contractAddress: currencyAddress,
    address: address!,
    queryOptions: { enabled: !!address && !isEth },
  });

  const balanceWei = (isEth ? ethBalanceWei : tokenBalanceWei) ?? 0n;

  const balanceDecimals = fromDecimals(balanceWei);
  const entryAmountFloat = entryAmount ? parseFloat(entryAmount) : 0;
  const rollsAmountFloat = rollsAmount ? parseFloat(rollsAmount) : 1;

  const isBalanceSufficient = entryAmountFloat * rollsAmountFloat <= parseFloat(balanceDecimals) - gasBuffer;
  const isInputValid = getIsInputValid(Number(minPlayAmount), Number(maxPlayAmount));

  return (
    <Stack
      spacing={10}
      p={6}
      backgroundColor="interactive-02"
      borderRadius="container"
      backdropFilter="blur(10px)"
      {...props}
    >
      <Stack spacing={4}>
        <QuantumEntryEthAmount
          entryAmount={entryAmount}
          setEntryAmount={setEntryAmount}
          currentCurrency={currentCurrency}
          setCurrentCurrency={setCurrentCurrency}
          entryAmountFloat={entryAmountFloat}
          rollsAmountFloat={rollsAmountFloat}
          balanceWei={balanceWei}
          isBalanceSufficient={isBalanceSufficient}
          minPlayAmount={minPlayAmount}
          maxPlayAmount={maxPlayAmount}
        />
        <QuantumEntryRollsAmount
          rollsAmount={rollsAmount}
          rollsAmountFloat={rollsAmountFloat}
          setRollsAmount={setRollsAmount}
        />
        <QuantumEntryAdvanced
          stopOnProfitAmount={stopOnProfitAmount}
          setStopOnProfitAmount={setStopOnProfitAmount}
          stopOnLossAmount={stopOnLossAmount}
          setStopOnLossAmount={setStopOnLossAmount}
          currentCurrency={currentCurrency}
        />
      </Stack>

      <PlayCTAWrapper
        isWalletBlocked={isWalletBlocked}
        hasInsufficientBalance={!isBalanceSufficient}
        canSkip={false}
        currencyAddress={currencyAddress}
        numberOfRounds={rollsAmountFloat}
        amountPerRoundWei={toDecimals(entryAmount)}
      >
        {isRolling ? (
          <Button isDisabled>
            {t("quantum::Charging...")}
            <Spinner size="sm" borderWidth="1px" ml={1} />
          </Button>
        ) : (
          <Button colorScheme="brand" onClick={onPlayClick} isDisabled={isDisabled || isGenerating || !isInputValid}>
            {t("quantum::Play")}
          </Button>
        )}
      </PlayCTAWrapper>
    </Stack>
  );
};

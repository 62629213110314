import { Address } from "viem";
import { graphql } from "@looksrare/utils";
import { graphql as generatedGql, QuantumGamesFilterInput, QuantumSort } from "@looksrare/yolo-games-gql-typegen";

export interface QuantumPagination {
  first: number;
  cursor?: string;
}

export interface UserLatestQuantumGameInput {
  player: Address;
}

export const getUserLatestQuantumGame = async ({ player }: UserLatestQuantumGameInput) => {
  const query = generatedGql(/* GraphQL */ `
    query GetUserLatestQuantumGame($address: Address!, $contract: QuantumContract!) {
      user(address: $address) {
        latestQuantumGame(contract: $contract) {
          ...QuantumGame
        }
      }
    }
  `);

  const res = await graphql({
    query,
    params: {
      contract: "QUANTUM_V1_BLAST",
      address: player,
    },
  });

  return res.user?.latestQuantumGame;
};

export interface QuantumGamesInput {
  filter: Omit<QuantumGamesFilterInput, "contracts">;
  pagination?: QuantumPagination;
  sort?: QuantumSort;
}

export const getQuantumGames = async ({ filter, pagination, sort }: QuantumGamesInput) => {
  const query = generatedGql(/* GraphQL */ `
    query GetQuantumGames($filter: QuantumGamesFilterInput!, $pagination: PaginationInput, $sort: QuantumSort) {
      quantumGames(filter: $filter, pagination: $pagination, sort: $sort) {
        ...QuantumGame
      }
    }
  `);

  const res = await graphql({
    query,
    params: {
      filter: {
        contracts: ["QUANTUM_V1_BLAST"],
        ...filter,
      },
      pagination,
      sort,
    },
  });

  return res.quantumGames;
};

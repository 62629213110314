import { useState } from "react";
import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { getSupportedNetworkFromChainId, useAssertNetwork } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { QuantumEntryMainView } from "./components/QuantumEntryMainView/QuantumEntryMainView";
import { QuantumEntryTransactionView } from "./components/QuantumEntryTransactionView/QuantumEntryTransactionView";

enum QuantumEntryViews {
  Main,
  Transaction,
}

interface QuantumEntryProps extends BoxProps {
  onSuccess?: () => void;
  isRolling?: boolean;
  isGenerating?: boolean;
  isDisabled?: boolean;
}

export const QuantumEntry = ({ onSuccess, isRolling, isGenerating, isDisabled, ...props }: QuantumEntryProps) => {
  const [view, setView] = useState(QuantumEntryViews.Main);
  const { chainId } = useAccount();

  const onPlayClick = useAssertNetwork({
    network: getSupportedNetworkFromChainId(chainId ?? 0),
    onSuccess: () => setView(QuantumEntryViews.Transaction),
  });

  const handleSuccess = () => {
    setView(QuantumEntryViews.Main);
    onSuccess?.();
  };

  return (
    <Box overflow="hidden" {...props}>
      <HStack
        position="relative"
        spacing={4}
        alignItems="start"
        transition="left 400ms"
        left={view === QuantumEntryViews.Main ? "0" : "calc(-100% - 16px)"}
      >
        <QuantumEntryMainView
          onPlayClick={onPlayClick}
          isRolling={isRolling}
          isGenerating={isGenerating}
          isDisabled={isDisabled}
          width="100%"
          flexShrink={0}
        />

        <QuantumEntryTransactionView
          isActive={view === QuantumEntryViews.Transaction}
          onSuccess={handleSuccess}
          onBack={() => setView(QuantumEntryViews.Main)}
          width="100%"
          flexShrink={0}
        />
      </HStack>
    </Box>
  );
};

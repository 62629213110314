import { HStack, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { type Currency } from "@looksrare/config";
import { Button, NumberInput, Text } from "@looksrare/uikit";
import { formatNumberToLocale, formatToSignificant, useGlobalStore } from "@looksrare/utils";
import { useQuantumConfig } from "../../../../hooks/useQuantumConfig";
import { quantumInputMaxDecimals } from "../../../../config/settings";
import { EntryCurrencyMenu } from "../../../../../shared/components/EntryCurrencyMenu";
import type { SetCurrencyHandler } from "../../../../../shared/types";

interface QuantumEntryEthAmountProps {
  entryAmount: string;
  setEntryAmount: (value: string) => void;
  currentCurrency: Currency;
  setCurrentCurrency: SetCurrencyHandler;
  entryAmountFloat: number;
  rollsAmountFloat: number;
  balanceWei: bigint;
  isBalanceSufficient: boolean;
  minPlayAmount: string;
  maxPlayAmount: string;
}

export const QuantumEntryEthAmount = ({
  entryAmount,
  setEntryAmount,
  currentCurrency,
  setCurrentCurrency,
  entryAmountFloat,
  rollsAmountFloat,
  balanceWei,
  isBalanceSufficient,
  minPlayAmount,
  maxPlayAmount,
}: QuantumEntryEthAmountProps) => {
  const { t } = useTranslation();
  const isPlaying = useGlobalStore((state) => state.isPlaying); // Hide balances while a game is running
  const { supportedCurrencies } = useQuantumConfig();

  const minPlayAmountPerRound = Number(minPlayAmount) / rollsAmountFloat;
  const maxPlayAmountPerRound = Number(maxPlayAmount) / rollsAmountFloat;

  const newHalveEntryFloat = entryAmountFloat / 2;
  const newDoubleEntryFloat = entryAmountFloat * 2;

  const halveEntryAmount = () => setEntryAmount(newHalveEntryFloat.toFixed(quantumInputMaxDecimals));
  const doubleEntryAmount = () => setEntryAmount(newDoubleEntryFloat.toFixed(quantumInputMaxDecimals));

  const isHalveButtonDisabled = newHalveEntryFloat < minPlayAmountPerRound;
  const isDoubleButtonDisabled = newDoubleEntryFloat > maxPlayAmountPerRound;

  const inputDecimals = entryAmount.split(/[.,]/g)[1]?.length || 0;
  const isMultipleOfEntryValue = inputDecimals <= quantumInputMaxDecimals;

  const maxEntryAmount = () => {
    const decimalsMultiplier = 10 ** quantumInputMaxDecimals;
    const newEntryAmount = Math.floor(maxPlayAmountPerRound * decimalsMultiplier) / decimalsMultiplier;

    setEntryAmount(newEntryAmount.toFixed(quantumInputMaxDecimals));
  };

  return (
    <Stack spacing={2}>
      <HStack justifyContent="space-between">
        <Text textStyle="detail" color="text-03" bold>
          {t("quantum::Entry")}
        </Text>

        <Text textStyle="helper" color="text-03" layerStyle={isPlaying ? "animatedFocusLoss" : "none"}>
          {t("quantum::Balance: {{balance}}", { balance: formatToSignificant(balanceWei, 5) })}
        </Text>
      </HStack>

      <InputGroup zIndex={1}>
        <NumberInput value={entryAmount} onTextChange={setEntryAmount} height="48px" pr="120px" />
        <InputRightElement pr={2} width="fit-content" height="48px">
          <EntryCurrencyMenu
            currencies={supportedCurrencies}
            currentCurrency={currentCurrency}
            onSelectCurrency={setCurrentCurrency}
          />
        </InputRightElement>
      </InputGroup>

      <HStack>
        <Button
          onClick={halveEntryAmount}
          variant="outline"
          colorScheme="secondary"
          size="xxs"
          flex={1}
          isDisabled={isHalveButtonDisabled}
        >
          ½
        </Button>
        <Button
          onClick={doubleEntryAmount}
          variant="outline"
          colorScheme="secondary"
          size="xxs"
          flex={1}
          isDisabled={isDoubleButtonDisabled}
        >
          x2
        </Button>
        <Button onClick={maxEntryAmount} variant="outline" colorScheme="secondary" size="xxs" flex={1}>
          {t("quantum::Max")}
        </Button>
      </HStack>

      {entryAmountFloat < minPlayAmountPerRound && (
        <Text textStyle="helper" color="text-error">
          {t("quantum::Minimum Play Amount: {{amount}}", {
            amount: formatNumberToLocale(minPlayAmountPerRound, 0, 10),
          })}
        </Text>
      )}

      {entryAmountFloat > maxPlayAmountPerRound && (
        <Text textStyle="helper" color="text-error">
          {t("quantum::Maximum Play Amount: {{amount}}", {
            amount: formatNumberToLocale(maxPlayAmountPerRound, 0, 10),
          })}
        </Text>
      )}

      {!isMultipleOfEntryValue && (
        <Text textStyle="helper" color="text-error">
          {t("quantum::Must be a multiple of {{amount}} {{currencySymbol}}", {
            amount: minPlayAmountPerRound,
            currencySymbol: currentCurrency,
          })}
        </Text>
      )}

      {!isBalanceSufficient && (
        <Text textStyle="helper" color="text-error">
          {t("quantum::Insufficient Funds")}
        </Text>
      )}
    </Stack>
  );
};

import { Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack } from "@chakra-ui/react";
import clamp from "lodash/clamp";
import { useQuantumStore } from "../../stores/QuantumStore";
import {
  quantumMaxAboveCutOff,
  quantumMaxBelowCutOff,
  quantumMinAboveCutOff,
  quantumMinBelowCutOff,
} from "../../config/settings";

export const QuantumSlider = (props: SliderProps) => {
  const { cutOff, isAbove, setCutOff } = useQuantumStore();

  const handleSliderBoundary = (value: number) => {
    const minCutOff = isAbove ? quantumMinAboveCutOff : quantumMinBelowCutOff;
    const maxCutOff = isAbove ? quantumMaxAboveCutOff : quantumMaxBelowCutOff;

    return clamp(value, minCutOff, maxCutOff);
  };

  return (
    <Slider
      value={Number(cutOff)}
      onChange={(value: number) => setCutOff(handleSliderBoundary(value).toString())}
      focusThumbOnChange={false}
      {...props}
    >
      <SliderTrack
        height="8px"
        backgroundColor={isAbove ? "support-success" : "support-error"}
        transition="background 400ms"
        borderRadius="circular"
      >
        <SliderFilledTrack
          backgroundColor={isAbove ? "support-error" : "support-success"}
          transition="background 400ms"
        />
      </SliderTrack>

      <SliderThumb width="16px" height="27px" backgroundColor="white" borderRadius="circular" />
    </Slider>
  );
};

import { useState } from "react";
import { Box, HStack, IconButton, Stack, StackProps } from "@chakra-ui/react";
import {
  ApproveErc20SpenderStep,
  ApproveTransferManagerStep,
  ArrowLeftIcon,
  BulkTransactionStep,
  Text,
} from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { multiplyWeiByNumber, toDecimals, useAddressesByNetwork } from "@looksrare/utils";
import { useQuantumStore } from "../../../../stores/QuantumStore";
import { QuantumEntryTransactionDetails } from "./QuantumEntryTransactionDetails";
import { QuantumEntryTransactionRow } from "./QuantumEntryTransactionRow";

enum TransactionSteps {
  Approval = "Approval",
  Assets = "Assets",
  Transaction = "Transaction",
}

interface QuantumEntryTransactionViewProps extends StackProps {
  isActive: boolean;
  onSuccess: () => void;
  onBack: () => void;
}

export const QuantumEntryTransactionView = ({
  isActive,
  onSuccess,
  onBack,
  ...props
}: QuantumEntryTransactionViewProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<TransactionSteps>(TransactionSteps.Approval);
  const addresses = useAddressesByNetwork();

  const { entryAmount, rollsAmount, currentCurrency } = useQuantumStore();
  const isEth = currentCurrency === "ETH";

  const entryAmountWei = toDecimals(entryAmount);
  const rollsAmountNumber = Number(rollsAmount);
  const totalAmountWei = multiplyWeiByNumber(entryAmountWei, rollsAmountNumber);

  const getStepStatus = (step: TransactionSteps) => {
    if (currentStep === step) {
      return "current";
    }

    if (currentStep > step) {
      return "past";
    }

    return "future";
  };

  return (
    <Stack
      spacing={0}
      backgroundColor="interactive-02"
      borderRadius="container"
      backdropFilter="blur(10px)"
      visibility={isActive ? "visible" : "hidden"}
      transition="visibility 400ms"
      {...props}
    >
      <HStack spacing={4} p={4}>
        <IconButton
          onClick={onBack}
          icon={<ArrowLeftIcon boxSize={5} />}
          aria-label={t("quantum::Go Back")}
          variant="ghost"
          colorScheme="secondary"
          size="xs"
        />

        <Text textStyle="heading-04" bold>
          {t("quantum::Confirm Entry")}
        </Text>
      </HStack>

      <Stack spacing={4} px={4} pt={6} pb={8}>
        <QuantumEntryTransactionDetails
          entryAmountWei={entryAmountWei}
          rollsAmountNumber={rollsAmountNumber}
          totalAmountWei={totalAmountWei}
          currencySymbol={currentCurrency}
        />

        <Text textStyle="detail" color="text-02">
          {t("quantum::Confirm your entry in your wallet app.")}
        </Text>
      </Stack>

      <Stack spacing={4} px={4} pb={4}>
        {isEth ? (
          <Box p={4} bg="ui-bg" borderRadius="container">
            {isActive && <QuantumEntryTransactionRow isActive onSuccess={onSuccess} />}
          </Box>
        ) : (
          <>
            <BulkTransactionStep
              status={getStepStatus(TransactionSteps.Approval)}
              title={t("quantum::Transfer Manager Approval")}
              collapse={currentStep !== TransactionSteps.Approval}
            >
              <ApproveTransferManagerStep
                ctaText={t("quantum::Transfer Manager Approval")}
                transferManager={addresses.TRANSFER_MANAGER_CLASSIC_GAMES}
                operator={addresses.QUANTUM}
                isStepActive={isActive && currentStep === TransactionSteps.Approval}
                onComplete={() => setCurrentStep(TransactionSteps.Assets)}
              />
            </BulkTransactionStep>

            <BulkTransactionStep
              status={getStepStatus(TransactionSteps.Assets)}
              title={t("quantum::Assets Approval")}
              collapse={currentStep !== TransactionSteps.Assets}
            >
              <ApproveErc20SpenderStep
                ctaText={t("quantum::Assets Approval")}
                currency={addresses[currentCurrency]}
                spender={addresses.TRANSFER_MANAGER_CLASSIC_GAMES}
                amount={totalAmountWei}
                isStepActive={isActive && currentStep === TransactionSteps.Assets}
                onComplete={() => setCurrentStep(TransactionSteps.Transaction)}
              />
            </BulkTransactionStep>
            <BulkTransactionStep
              status={getStepStatus(TransactionSteps.Transaction)}
              title={t("quantum::Transaction")}
              collapse={currentStep !== TransactionSteps.Transaction}
            >
              {isActive && currentStep === TransactionSteps.Transaction && (
                <QuantumEntryTransactionRow isActive onSuccess={onSuccess} />
              )}
            </BulkTransactionStep>
          </>
        )}
      </Stack>
    </Stack>
  );
};

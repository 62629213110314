import { useCallback } from "react";
import { encodeFunctionData } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";
import { CLASSIC_GAMES_GAS_BUFFER, Currency, QuantumAbi } from "@looksrare/config";
import { DataLayerEventNames, multiplyWeiByNumber, NoPublicClientError, useAddressesByNetwork } from "@looksrare/utils";
import { useSendGameEntryAnalyticsEvent } from "../../../../shared/analytics";

interface UsePlayQuantumInput {
  numberOfRounds: number;
  playAmountPerRound: bigint;
  currency: Currency;
  stopGain: bigint;
  stopLoss: bigint;
  isAbove: boolean;
  multiplier: bigint;
  vrfFee: bigint;
}

export const usePlayQuantum = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { QUANTUM, ...addresses } = useAddressesByNetwork();
  const sendEntryAnalyticsEvent = useSendGameEntryAnalyticsEvent(DataLayerEventNames.QUANTUM_DEPOSIT);

  return useCallback(
    async ({
      numberOfRounds,
      playAmountPerRound,
      currency,
      stopGain,
      stopLoss,
      isAbove,
      multiplier,
      vrfFee,
    }: UsePlayQuantumInput) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const entryValueBi = multiplyWeiByNumber(playAmountPerRound, numberOfRounds);
      const currencyAddress = addresses[currency];

      const value = currency === "ETH" ? entryValueBi + vrfFee : vrfFee;

      const [account] = await walletClient.getAddresses();
      const args = [
        numberOfRounds,
        playAmountPerRound,
        currencyAddress,
        stopGain,
        stopLoss,
        isAbove,
        multiplier,
      ] as const;

      const gas = await publicClient.estimateGas({
        to: QUANTUM,
        data: encodeFunctionData({
          abi: QuantumAbi,
          functionName: "play",
          args,
        }),
        value,
        account,
      });

      const { request } = await publicClient.simulateContract({
        address: QUANTUM,
        abi: QuantumAbi,
        functionName: "play",
        args,
        gas: multiplyWeiByNumber(gas, CLASSIC_GAMES_GAS_BUFFER),
        account,
        value,
      });

      const hash = await walletClient.writeContract(request);
      sendEntryAnalyticsEvent(entryValueBi, currency);
      return hash;
    },
    [QUANTUM, publicClient, walletClient]
  );
};

export const useRefundQuantum = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { QUANTUM } = useAddressesByNetwork();

  return useCallback(async () => {
    if (!walletClient) {
      throw Error("No wallet client found");
    }
    if (!publicClient) {
      throw new NoPublicClientError();
    }
    const [account] = await walletClient.getAddresses();
    const { request } = await publicClient.simulateContract({
      address: QUANTUM,
      abi: QuantumAbi,
      functionName: "refund",
      args: [],
      account,
    });

    return await walletClient.writeContract(request);
  }, [QUANTUM, publicClient, walletClient]);
};

import { BoxProps, Flex, HStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { QuantumRoll } from "../../types/general";
import { QuantumFeedTile } from "./components/QuantumFeedTile";

const sharedFadeProps: Partial<BoxProps["_before"]> = {
  content: '""',
  position: "absolute",
  top: 0,
  bottom: 0,
  width: "24px",
  transition: "opacity 200ms",
};

interface QuantumFeedProps {
  results: QuantumRoll[];
}

export const QuantumFeed = ({ results }: QuantumFeedProps) => {
  const feedRef = useRef<HTMLDivElement>(null);

  const [shouldShowLeftFade, setShouldShowLeftFade] = useState(true);
  const [shouldShowRightFade, setShouldShowRightFade] = useState(false);

  useEffect(() => {
    if (feedRef.current) {
      const feedScrollWidth = feedRef.current.scrollWidth;
      const feedWidth = feedRef.current.clientWidth;

      // This makes the new tile come in smoothly
      feedRef.current.scrollTo({
        left: feedScrollWidth,
        behavior: "smooth",
      });

      // This ensures the little fades on the edges are shown only when necessary
      feedRef.current.onscroll = (event) => {
        const scrollAmount = (event.target as HTMLDivElement).scrollLeft;

        setShouldShowLeftFade(scrollAmount > 0);
        setShouldShowRightFade(scrollAmount + feedWidth < feedScrollWidth);
      };
    }
  }, [results]);

  return (
    <Flex
      position="relative"
      justifyContent="flex-end"
      width="100%"
      py={3}
      _before={{
        ...sharedFadeProps,
        left: 0,
        background: "linear-gradient(to right, rgba(31, 42, 55, 0.00), rgba(0, 0, 0, 0))",
        opacity: shouldShowLeftFade ? 1 : 0,
      }}
      _after={{
        ...sharedFadeProps,
        right: 0,
        background: "linear-gradient(to left, rgba(31, 42, 55, 0.00), rgba(0, 0, 0, 0))",
        opacity: shouldShowRightFade ? 1 : 0,
      }}
    >
      <HStack
        ref={feedRef}
        overflowX="auto"
        sx={{
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {results.map((result, index) => (
          <QuantumFeedTile key={index} {...result} />
        ))}
      </HStack>
    </Flex>
  );
};

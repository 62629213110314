import { Box } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";
import { QuantumRoll } from "../../../types/general";
import { getDecimalResult } from "../../../utils/general";

export const QuantumFeedTile = ({ value, isWin, isAbove }: QuantumRoll) => {
  const valueFloat = Number(value);

  return (
    <Box
      px={3}
      py={1}
      width="fit-content"
      backgroundColor={isWin ? "support-success" : "ui-bg"}
      borderRadius="mini"
      boxShadow="shadow-card-dark"
    >
      <Text textStyle="body" lineHeight="24px" color={isWin ? "text-inverse" : "text-01"} bold>
        {formatNumberToLocale(getDecimalResult({ value: valueFloat, isWin, isAbove }))}
      </Text>
    </Box>
  );
};

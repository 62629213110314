import { Flex, HStack, Stack, StackProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { QuantumSliderNotch } from "./components/QuantumSliderNotch";

interface QuantumSliderSurroundProps extends StackProps {
  children: ReactNode;
}

export const QuantumSliderSurround = ({ children, ...props }: QuantumSliderSurroundProps) => {
  return (
    <Stack spacing={0} {...props}>
      <Flex px={6} py={3} backgroundColor="interactive-02" borderRadius="container" width="100%">
        {children}
      </Flex>

      <HStack justifyContent="space-between" px={6}>
        <QuantumSliderNotch value={0} />
        <QuantumSliderNotch value={25} />
        <QuantumSliderNotch value={50} />
        <QuantumSliderNotch value={75} />
        <QuantumSliderNotch value={100} />
      </HStack>
    </Stack>
  );
};

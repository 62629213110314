import { Box, VStack } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";

interface QuantumSliderNotchProps {
  value: number;
}

export const QuantumSliderNotch = ({ value }: QuantumSliderNotchProps) => {
  return (
    <VStack spacing={1.5} width="0" overflow="visible">
      <Box width="12px" height="6px" borderBottomRadius="mini" backgroundColor="interactive-02" />

      <Text textStyle="detail" bold>
        {value}
      </Text>
    </VStack>
  );
};

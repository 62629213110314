import { useState } from "react";
import { Flex, HStack, Spinner, Stack } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { SortOptions } from "../../../shared/components";
import { ClassicGamesHistorySort } from "../../../shared/types";
import { useQuantumGames } from "../../network/graphql/hooks";
import { getClassicGamesGraphqlSort } from "../../../shared/utils";
import { QuantumHistoryTable } from "./components/QuantumHistoryTable";

export const QuantumHistoryView = () => {
  const { t } = useTranslation();
  const [sort, setSort] = useState(ClassicGamesHistorySort.ALL);
  const { address } = useAccount();

  const {
    data: quantumGames,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isSuccess: isHistoryQuerySuccess,
  } = useQuantumGames(
    {
      filter: {
        player: sort === ClassicGamesHistorySort.MINE ? address : undefined,
      },
      sort: getClassicGamesGraphqlSort(sort),
    },
    sort,
    {
      refetchInterval: 60_000, // every 60 secs
    }
  );

  return (
    <Stack spacing={4} width="100%" maxWidth="1536px" mx="auto">
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "flex-start", lg: "center" }}
        flexDirection={{ base: "column", lg: "row" }}
        gap={4}
      >
        <HStack spacing={4}>
          <Text textStyle="heading-03" bold>
            {t("quantum::Recent Rolls")}
          </Text>

          {isLoading && <Spinner />}
        </HStack>
        <SortOptions sort={sort} setSort={setSort} />
      </Flex>

      {!!quantumGames && (
        <QuantumHistoryTable
          games={quantumGames.pages.flat()}
          hasNextPage={hasNextPage}
          isHistoryQuerySuccess={isHistoryQuerySuccess}
          sort={sort}
          onClickLoadMore={fetchNextPage}
        />
      )}
    </Stack>
  );
};

import { Divider, HStack, Stack } from "@chakra-ui/react";
import { Text, currencyTokenIconMap } from "@looksrare/uikit";
import { formatNumberToLocale, formatToSignificant, tabularNums } from "@looksrare/utils";
import { useTranslation } from "next-i18next";

interface QuantumEntryTransactionDetailsProps {
  entryAmountWei: bigint;
  rollsAmountNumber: number;
  totalAmountWei: bigint;
  currencySymbol: string;
}

export const QuantumEntryTransactionDetails = ({
  entryAmountWei,
  rollsAmountNumber,
  totalAmountWei,
  currencySymbol,
}: QuantumEntryTransactionDetailsProps) => {
  const { t } = useTranslation();

  const CurrencyIcon = currencyTokenIconMap[currencySymbol];

  return (
    <Stack spacing={1}>
      <HStack>
        <Text color="text-02">{t("quantum::Entry")}</Text>

        <Divider flex={1} />

        <HStack spacing={0.5}>
          <Text sx={tabularNums} bold>
            {formatToSignificant(entryAmountWei, 5)}
          </Text>

          <CurrencyIcon boxSize={4} />
        </HStack>
      </HStack>

      <HStack>
        <Text color="text-02">{t("quantum::Number of Rolls")}</Text>

        <Divider flex={1} />

        <Text sx={tabularNums} bold>
          {formatNumberToLocale(rollsAmountNumber, 0)}
        </Text>
      </HStack>

      <HStack>
        <Text bold>{t("quantum::Total Amount")}</Text>

        <Divider flex={1} />

        <HStack spacing={0.5}>
          <Text sx={tabularNums} bold>
            {formatToSignificant(totalAmountWei, 5)}
          </Text>

          <CurrencyIcon boxSize={4} />
        </HStack>
      </HStack>
    </Stack>
  );
};

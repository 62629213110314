import { getTopicName, TopicName, useRealtimeSubscription } from "@looksrare/utils";

export const useQuantumPageViews = () => {
  const topicName = getTopicName({ name: TopicName.quantumActiveViews });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useQuantumActivePlayers = () => {
  const topicName = getTopicName({ name: TopicName.quantumActivePlayers });
  return useRealtimeSubscription<number>(topicName);
};

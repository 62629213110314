import { ReactNode } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { quantumDiceSize } from "../QuantumDice/QuantumDice";
import { diceTransitionDurationMs } from "../../config/settings";

interface QuantumDiceMoverProps extends FlexProps {
  value: string;
  children: ReactNode;
}

export const QuantumDiceMover = ({ value, children, ...props }: QuantumDiceMoverProps) => {
  const valueNumber = Number(value);

  return (
    <Flex
      position="relative"
      mx={{ base: `calc(${quantumDiceSize.base} / 2 + 24px)`, md: `calc(${quantumDiceSize.md} / 2 + 16px)` }}
      height={quantumDiceSize}
      {...props}
    >
      <Flex
        position="absolute"
        left={`${valueNumber}%`}
        transform="translateX(-50%)"
        transition={`left ${diceTransitionDurationMs}ms`}
      >
        {children}
      </Flex>
    </Flex>
  );
};

import { QuantumAbi } from "@looksrare/config";
import { BigIntish, useAddressesByNetwork } from "@looksrare/utils";
import { Address } from "viem";
import { useReadContracts } from "wagmi";

interface QuantumMinMaxPlayAmountInput {
  currencyAddress: Address;
  multiplier: BigIntish;
}

export const useQuantumMinMaxPlayAmount = ({ currencyAddress, multiplier }: QuantumMinMaxPlayAmountInput) => {
  const { QUANTUM } = useAddressesByNetwork();

  const { data: results, ...query } = useReadContracts({
    contracts: [
      {
        abi: QuantumAbi,
        address: QUANTUM,
        functionName: "minPlayAmountPerGame",
        args: [currencyAddress, BigInt(multiplier)],
      },
      {
        abi: QuantumAbi,
        address: QUANTUM,
        functionName: "maxPlayAmountPerGame",
        args: [currencyAddress, BigInt(multiplier)],
      },
    ],
  });

  const minPlayAmountPerGame = results?.[0].result ?? 0n;
  const maxPlayAmountPerGame = results?.[1].result ?? 0n;

  return { data: [minPlayAmountPerGame, maxPlayAmountPerGame], ...query };
};

/**
 * @param cutOff value from 0-100
 * @param isAbove decides if the chance is above or below the cutOff
 * @returns chance based on cutOff and isAbove. If cutOff is 75, chance is 75%, if isAbove is true, chance is 25%
 */
export const getQuantumChance = (cutOff: string, isAbove: boolean) => {
  const cutOffNumber = parseFloat(cutOff);

  if (isNaN(cutOffNumber)) {
    return "";
  }

  const chance = isAbove ? 100 - cutOffNumber : cutOff;

  return chance.toString();
};

/**
 * @param chance value from 0-100, chance the user wins
 * @param isAbove decides if the chance is above or below the cutOff
 * @returns cutOff based on the given chance. If chance is 75%, cutOff is 75, if isAbove is true, cutOff is 25
 */
export const getQuantumCutOffByChance = (chance: string, isAbove: boolean) => {
  const chanceNumber = parseFloat(chance);

  if (isNaN(chanceNumber)) {
    return "";
  }

  const cutOff = isAbove ? 100 - chanceNumber : chanceNumber;

  return cutOff.toString();
};

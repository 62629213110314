import { useEffect } from "react";
import { useQuantumConfig } from "./useQuantumConfig";

export const useQuantumGameSound = () => {
  const { isMuted } = useQuantumConfig();

  useEffect(() => {
    if (!isMuted) {
      const backgroundSound = new Audio("/sounds/quantum/bg_track.mp3");

      backgroundSound.loop = true;
      backgroundSound.volume = 0.3;

      backgroundSound.play();

      return () => {
        backgroundSound.pause();
        backgroundSound.remove();
      };
    }
  }, [isMuted]);
};

import { useCallback, useEffect } from "react";
import { Flex, Grid, Stack } from "@chakra-ui/react";
import { useAccount, usePublicClient } from "wagmi";
import { INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import last from "lodash/last";
import { isHash, isHashEqual, sleep, useGlobalStore, useHandleModalStep, useWidgetBotCrate } from "@looksrare/utils";
import { SelfExclusionModal, useThrowConfettiFromCenter, useToast, useWidgetBotOptions } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { QuantumEntry } from "../../components/QuantumEntry/QuantumEntry";
import { QuantumFeed } from "../../components/QuantumFeed/QuantumFeed";
import { QuantumSliderSurround } from "../../components/QuantumSliderSurround/QuantumSliderSurround";
import { QuantumSlider } from "../../components/QuantumSlider/QuantumSlider";
import { QuantumChanceInput } from "../../components/QuantumChanceInput/QuantumChanceInput";
import { QuantumDice } from "../../components/QuantumDice/QuantumDice";
import { useInvalidateQuantumGames, useUserLatestQuantumGame } from "../../network/graphql/hooks";
import { useQuantumRolls } from "../../hooks/useQuantumRolls";
import { QuantumDiceMover } from "../../components/QuantumDiceMover/QuantumDiceMover";
import { QuantumGameText } from "../../components/QuantumGameText/QuantumGameText";
import { QuantumBackground } from "../../components/QuantumBackground/QuantumBackground";
import { useQuantumGameSound } from "../../hooks/useQuantumGameSound";
import { useQuantumConfig } from "../../hooks/useQuantumConfig";
import { useRefundQuantum } from "../../network/contract/write/hooks";
import { useQuantumStore } from "../../stores/QuantumStore";

export const QuantumMainView = () => {
  const { t } = useTranslation();
  useWidgetBotCrate(useWidgetBotOptions());
  useQuantumGameSound();

  const { address } = useAccount();
  const publicClient = usePublicClient();
  const throwConfetti = useThrowConfettiFromCenter();
  const { toast } = useToast();

  const invalidateQuantumGames = useInvalidateQuantumGames();
  const { selfTimeoutUntil } = useQuantumConfig();
  const refund = useRefundQuantum();
  const { drawingGameTransactionHash } = useQuantumStore();

  const onRollEnd = useCallback(
    (_isWin: boolean) => {
      if (_isWin) {
        throwConfetti();
      }
      invalidateQuantumGames();
    },
    [invalidateQuantumGames, throwConfetti]
  );

  const { data: latestGame, refetch: refetchLatestGame } = useUserLatestQuantumGame(address!, {
    enabled: !!address,
    refetchInterval: (query) => {
      if (drawingGameTransactionHash === null) {
        return false;
      }

      const queryDrawingTransactionHash = query.state.data?.drawingTransactionHash;

      if (
        !isHash(queryDrawingTransactionHash) ||
        !isHashEqual(queryDrawingTransactionHash, drawingGameTransactionHash) ||
        query.state.data?.status === "DRAWING"
      ) {
        return INDEXER_REFETCH_DELAY_MS;
      }

      return false;
    },
  });

  const { rolls, wonAmountWei, isWin, wonCurrencySymbol, isRolling, isGenerating, onEntry } = useQuantumRolls({
    latestGame,
    onRollEnd,
  });

  useEffect(() => {
    const setIsPlaying = useGlobalStore.getState().setIsPlaying;
    const isPlaying = isGenerating || isRolling;
    setIsPlaying(isPlaying);
  }, [isGenerating, isRolling]);

  const { handleSubmit: handleRefund, isInitialized: isProcessingRefund } = useHandleModalStep({
    onSubmit: async () => {
      const hash = await refund();
      const receipt = await publicClient?.waitForTransactionReceipt({ hash });
      if (receipt?.status === "success") {
        toast({
          title: t("quantum::Refund successful"),
          description: t("quantum::Your funds are now in your wallet."),
          status: "success",
        });
        await sleep(INDEXER_REFETCH_DELAY_MS);
        refetchLatestGame();
      }
    },
  });

  const onEntrySuccess = () => {
    onEntry();
    refetchLatestGame();
  };

  const lastRoll = last(rolls);
  const lastRollValue = lastRoll?.value ?? "0";
  const lastRollIsWin = lastRoll?.isWin ?? false;
  const lastRollIsAbove = lastRoll?.isAbove ?? false;
  const isCancelled = latestGame?.status === "CANCELLED";

  return (
    <>
      <Flex
        position="relative"
        p={{ base: 4, lg: 8 }}
        justifyContent="center"
        backgroundColor="ui-01"
        borderRadius="dialog"
        minHeight="360px"
        overflow="hidden"
      >
        <QuantumBackground isRolling={isRolling} position="absolute" inset={0} />
        <Grid
          gridTemplateColumns={{ base: "minmax(0, 1fr)", md: "minmax(0, 1fr) 320px" }}
          gap={{ base: 5, md: 6 }}
          width="100%"
          maxWidth="1536px"
        >
          <Stack
            spacing={6}
            height={{ base: "auto", lg: "512px" }}
            borderRadius="dialog"
            backgroundColor="interactive-02"
            backdropFilter="blur(10px)"
            overflow="hidden"
          >
            <QuantumFeed results={rolls} />

            <QuantumGameText
              currencySymbol={wonCurrencySymbol}
              isRolling={isRolling}
              isGenerating={isGenerating}
              isCancelled={isCancelled}
              isWin={isWin}
              isProcessingRefund={isProcessingRefund}
              wonAmountWei={wonAmountWei}
              onRefundClick={handleRefund}
              flex={1}
            />

            <QuantumDiceMover value={lastRollValue} opacity={isCancelled ? 0 : 1}>
              <QuantumDice value={lastRollValue} isWin={lastRollIsWin} isAbove={lastRollIsAbove} />
            </QuantumDiceMover>

            <QuantumSliderSurround mx={{ base: 4, lg: 6 }}>
              <QuantumSlider />
            </QuantumSliderSurround>

            <QuantumChanceInput />
          </Stack>

          <QuantumEntry
            onSuccess={onEntrySuccess}
            isRolling={isRolling}
            isGenerating={isGenerating}
            isDisabled={isCancelled}
          />
        </Grid>
      </Flex>

      {!!selfTimeoutUntil && <SelfExclusionModal endDate={selfTimeoutUntil} />}
    </>
  );
};

import { InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, NumberInput, Text } from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";
import { quantumMaxRolls, quantumMinRolls } from "../../../../config/settings";

interface QuantumEntryRollsAmountProps {
  rollsAmount: string;
  rollsAmountFloat: number;
  setRollsAmount: (value: string) => void;
}

export const QuantumEntryRollsAmount = ({
  rollsAmount,
  rollsAmountFloat,
  setRollsAmount,
}: QuantumEntryRollsAmountProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Text textStyle="detail" color="text-03" bold>
        {t("quantum::Number of Rolls")}
      </Text>

      <InputGroup>
        <NumberInput value={rollsAmount} onTextChange={setRollsAmount} height="48px" pr={16} />

        <InputRightElement width="fit-content" pr={2} height="48px">
          <Button
            onClick={() => setRollsAmount(quantumMaxRolls.toString())}
            variant="solid"
            colorScheme="secondary"
            size="xs"
            width="48px"
            height="36px"
          >
            {t("quantum::Max")}
          </Button>
        </InputRightElement>
      </InputGroup>

      {rollsAmountFloat < quantumMinRolls && (
        <Text textStyle="helper" color="text-error">
          {t("quantum::Minimum amount of rolls: {{amount}}", {
            amount: formatNumberToLocale(quantumMinRolls, 0),
          })}
        </Text>
      )}

      {rollsAmountFloat > quantumMaxRolls && (
        <Text textStyle="helper" color="text-error">
          {t("quantum::Maximum amount of rolls: {{amount}}", {
            amount: formatNumberToLocale(quantumMaxRolls, 0),
          })}
        </Text>
      )}
    </Stack>
  );
};

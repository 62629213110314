import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { type Currency } from "@looksrare/config";
import { StateSetter } from "@looksrare/utils";
import clamp from "lodash/clamp";
import { Hash } from "viem";
import {
  getQuantumChanceByMultiplier,
  getQuantumCutOffByMultiplier,
  getQuantumMultiplier,
  getQuantumMultiplierByChance,
} from "../utils/multiplierUtils";
import { getQuantumCutOffByChance, getQuantumChance } from "../utils/chanceUtils";
import {
  quantumMaxChance,
  quantumMaxBelowCutOff,
  quantumMaxMultiplier,
  quantumMaxRolls,
  quantumMinAboveCutOff,
  quantumMinChance,
  quantumMinBelowCutOff,
  quantumMinMultiplier,
  quantumMinRolls,
  quantumMaxAboveCutOff,
  quantumInputMaxDecimals,
} from "../config/settings";

interface QuantumState {
  entryAmount: string;
  rollsAmount: string;
  stopOnProfitAmount: string;
  stopOnLossAmount: string;
  isAbove: boolean;
  inputCutOff: string;
  inputMultiplier: string;
  inputChance: string;
  cutOff: string;
  multiplier: string;
  chance: string;
  currentCurrency: Currency;
  drawingGameTransactionHash: Hash | null;
  getIsInputValid: (minPlayAmount: number, maxPlayAmount: number) => boolean;
  setEntryAmount: StateSetter<string>;
  setRollsAmount: StateSetter<string>;
  setStopOnProfitAmount: StateSetter<string>;
  setStopOnLossAmount: StateSetter<string>;
  setIsAbove: StateSetter<boolean>;
  switchIsAbove: () => void;
  setCutOff: StateSetter<string>;
  setMultiplier: StateSetter<string>;
  setChance: StateSetter<string>;
  setCurrentCurrency: StateSetter<Currency>;
  setDrawingGameTransactionHash: StateSetter<Hash | null>;
}

export const useQuantumStore = create<QuantumState>()(
  persist(
    (set, get) => ({
      entryAmount: "0.01",
      rollsAmount: "2",
      stopOnProfitAmount: "0",
      stopOnLossAmount: "0",
      isAbove: false,
      inputCutOff: "50",
      inputMultiplier: "2",
      inputChance: "50",
      cutOff: "50",
      multiplier: "2",
      chance: "50",
      currentCurrency: "ETH",
      drawingGameTransactionHash: null,
      getIsInputValid: (minPlayAmount, maxPlayAmount) => {
        const { entryAmount, rollsAmount, isAbove, cutOff, multiplier, chance } = get();
        const entryAmountFloat = Number(entryAmount);
        const rollsAmountFloat = Number(rollsAmount);
        const totalEntryAmountFloat = entryAmountFloat * rollsAmountFloat;
        const cutOffFloat = Number(cutOff);
        const multiplierFloat = Number(multiplier);
        const chanceFloat = Number(chance);

        const minCutOff = isAbove ? quantumMinAboveCutOff : quantumMinBelowCutOff;
        const maxCutOff = isAbove ? quantumMaxAboveCutOff : quantumMaxBelowCutOff;

        const isEntryAmountInRange = totalEntryAmountFloat >= minPlayAmount && totalEntryAmountFloat <= maxPlayAmount;
        const isRollsAmountInRange = rollsAmountFloat >= quantumMinRolls && rollsAmountFloat <= quantumMaxRolls;
        const isCutOffInRange = cutOffFloat >= minCutOff && cutOffFloat <= maxCutOff;
        const isMultiplierInRange = multiplierFloat >= quantumMinMultiplier && multiplierFloat <= quantumMaxMultiplier;
        const isChanceInRange = chanceFloat >= quantumMinChance && chanceFloat <= quantumMaxChance;

        const inputDecimals = entryAmount.split(/[.,]/g)[1]?.length || 0;
        const isMultipleOfEntryValue = inputDecimals <= quantumInputMaxDecimals;

        const isInputValid =
          isEntryAmountInRange &&
          isRollsAmountInRange &&
          isCutOffInRange &&
          isMultiplierInRange &&
          isChanceInRange &&
          isMultipleOfEntryValue;

        return isInputValid;
      },
      setEntryAmount: (entryAmount) => {
        set({ entryAmount });
      },
      setRollsAmount: (rollsAmount) => {
        const rollsAmountNumber = parseInt(rollsAmount, 10);
        let newRollsAmount = "";

        if (!isNaN(rollsAmountNumber)) {
          newRollsAmount = clamp(rollsAmountNumber, quantumMinRolls, quantumMaxRolls).toString();
        }

        set({ rollsAmount: newRollsAmount });
      },
      setStopOnProfitAmount: (stopOnProfitAmount) => {
        set({ stopOnProfitAmount });
      },
      setStopOnLossAmount: (stopOnLossAmount) => {
        set({ stopOnLossAmount });
      },
      setIsAbove: (isAbove) => {
        set({ isAbove });
      },
      switchIsAbove: () => {
        set(({ isAbove, cutOff }) => {
          const cutOffNumber = parseFloat(cutOff);
          let newCutOff = "";

          if (!isNaN(cutOffNumber)) {
            newCutOff = (100 - cutOffNumber).toString();
          }

          return { isAbove: !isAbove, inputCutOff: Number(newCutOff).toFixed(2), cutOff: newCutOff };
        });
      },
      setCutOff: (cutOff) => {
        set(({ isAbove }) => {
          const multiplier = getQuantumMultiplier(cutOff, isAbove);
          const chance = getQuantumChance(cutOff, isAbove);

          return {
            inputCutOff: cutOff,
            inputMultiplier: Number(multiplier).toFixed(4),
            inputChance: Number(chance).toFixed(2),

            cutOff,
            multiplier,
            chance,
          };
        });
      },
      setMultiplier: (multiplier) => {
        set(({ isAbove }) => {
          const cutOff = getQuantumCutOffByMultiplier(multiplier, isAbove);
          const chance = getQuantumChanceByMultiplier(multiplier);

          return {
            inputCutOff: Number(cutOff).toFixed(2),
            inputMultiplier: multiplier,
            inputChance: Number(chance).toFixed(2),

            multiplier,
            cutOff: cutOff,
            chance: chance,
          };
        });
      },
      setChance: (chance) => {
        set(({ isAbove }) => {
          const cutOff = getQuantumCutOffByChance(chance, isAbove);
          const multiplier = getQuantumMultiplierByChance(chance);

          return {
            inputCutOff: Number(cutOff).toFixed(2),
            inputMultiplier: Number(multiplier).toFixed(4),
            inputChance: chance,

            cutOff,
            multiplier,
            chance,
          };
        });
      },
      setCurrentCurrency: (currentCurrency) => {
        set({ currentCurrency });
      },
      setDrawingGameTransactionHash: (drawingGameTransactionHash) => {
        set({ drawingGameTransactionHash });
      },
    }),
    { name: "quantum-state-1", storage: createJSONStorage(() => sessionStorage) }
  )
);

// Animation settings
export const timeBetweenRollsMs = 1000;
export const diceTransitionDurationMs = 500;
export const preEmptResultSoundMs = 100;

// Game settings
export const quantumMinPlayAmount = 0.0001;
export const quantumMinRolls = 1;
export const quantumMaxRolls = 10;
export const quantumMinBelowCutOff = 1;
export const quantumMaxBelowCutOff = 95;
export const quantumMinAboveCutOff = 5;
export const quantumMaxAboveCutOff = 100;
export const quantumMinMultiplier = 1.0526;
export const quantumMaxMultiplier = 100;
export const quantumMinChance = 1;
export const quantumMaxChance = 95;
export const quantumInputMaxDecimals = 5;

import { toDecimals } from "@looksrare/utils";
import { getQuantumChance } from "./chanceUtils";

/**
 * @param cutOff value from 0-100, if cutOff is 75, chance is 75%, if isAbove is true, chance is 25%
 * @param isAbove decides if the chance is above or below the cutOff
 * @returns multiplier based on the calculated chance. So if chance is 50%, multiplier is 2, however
 */
export const getQuantumMultiplier = (cutOff: string, isAbove: boolean) => {
  const cutOffNumber = parseFloat(cutOff);

  if (isNaN(cutOffNumber)) {
    return "";
  }

  const chance = getQuantumChance(cutOff, isAbove);

  const multiplier = 100 / Number(chance);

  return multiplier.toString();
};

/**
 * @param cutOff value from 0-100, if cutOff is 75, chance is 75%, if isAbove is true, chance is 25%
 * @param isAbove decides if the chance is above or below the cutOff
 * @returns multiplier based on the calculated chance. So if chance is 50%, multiplier is 2.
 * This version returns the multiplier as bigint as used in contract
 */
export const getQuantumMultiplierAsBigint = (cutOff: string, isAbove: boolean) => {
  const multiplier = getQuantumMultiplier(cutOff, isAbove);

  if (multiplier === "Infinity") {
    return 0n;
  }

  return toDecimals(multiplier.toString(), 4);
};

/**
 * @param chance value from 0-100, chance the user wins
 * @returns multiplier based on the given chance. So if chance is 50%, multiplier is 2, however.
 */
export const getQuantumMultiplierByChance = (chance: string) => {
  const chanceNumber = parseFloat(chance);

  if (isNaN(chanceNumber)) {
    return "";
  }

  const multiplier = 100 / chanceNumber;

  return multiplier.toString();
};

/**
 * @param multiplier multiplier value,
 * @returns chance the user wins based on the given multiplier. If multiplier is 1.98, chance is 50%.
 */
export const getQuantumChanceByMultiplier = (multiplier: string) => {
  const multiplierNumber = parseFloat(multiplier);

  if (isNaN(multiplierNumber)) {
    return "";
  }

  const chance = 100 / multiplierNumber;

  return chance.toString();
};

/**
 * @param multiplier multiplier value, including house advantage
 * @param isAbove decides if the chance is above or below the cutOff
 * @returns cutOff value from 0-100. If multiplier is 1.98, chance is 50%, so the cutOff is 50.
 * If multiplier is 1.1, chance is 90%. So the cutOff is 90, if isAbove is true, cutOff is 10
 */
export const getQuantumCutOffByMultiplier = (multiplier: string, isAbove: boolean) => {
  const multiplierNumber = parseFloat(multiplier);

  if (isNaN(multiplierNumber)) {
    return "";
  }

  const chance = getQuantumChanceByMultiplier(multiplier);

  const cutOff = isAbove ? 100 - Number(chance) : chance;

  return cutOff.toString();
};

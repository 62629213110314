import { useAccount } from "wagmi";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { Address } from "viem";
import { RQueryOptionsForFetcher } from "@looksrare/utils";
import { useCallback } from "react";
import { ClassicGamesHistorySort } from "../../../shared/types";
import { QuantumGamesInput, getQuantumGames, getUserLatestQuantumGame } from "./queries";

const BASE_QUANTUM_QUERY_KEY = "quantum";
const DEFAULT_PAGINATION = { first: 15 };

export const useUserLatestQuantumGame = (
  player: Address,
  options: RQueryOptionsForFetcher<typeof getUserLatestQuantumGame>
) => {
  return useQuery({
    queryKey: [BASE_QUANTUM_QUERY_KEY, "user-latest-game", player],
    queryFn: () => getUserLatestQuantumGame({ player }),
    ...options,
  });
};

export const useInvalidateQuantumGames = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [BASE_QUANTUM_QUERY_KEY, "games"] });
  }, [queryClient]);
};

export const useQuantumGames = (
  input: QuantumGamesInput,
  sort: ClassicGamesHistorySort,
  { refetchInterval }: { refetchInterval: number }
) => {
  const { isConnected } = useAccount();

  return useInfiniteQuery({
    queryKey: [BASE_QUANTUM_QUERY_KEY, "games", sort, input],
    queryFn: ({ pageParam }) => {
      if (!isConnected && sort === ClassicGamesHistorySort.MINE) {
        return [];
      }

      return getQuantumGames({ ...input, pagination: pageParam });
    },
    getNextPageParam: (lastPage) => {
      const pagination = input.pagination ?? DEFAULT_PAGINATION;

      if (!lastPage || lastPage.length < pagination.first) {
        return undefined;
      }

      const lastItem = lastPage[lastPage.length - 1];

      return {
        ...pagination,
        cursor: lastItem.id.toString(),
      };
    },
    initialPageParam: DEFAULT_PAGINATION,
    refetchInterval,
  });
};

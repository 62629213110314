import { useEffect } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { Fit, Layout, useRive, useStateMachineInput } from "@rive-app/react-canvas";

const ARTBOARD_NAME = "triple animation-no-startstop";
const STATE_MACHINE_NAME = "start-end-loop";

interface QuantumBackgroundProps extends BoxProps {
  isRolling: boolean;
}

export const QuantumBackground = ({ isRolling, ...props }: QuantumBackgroundProps) => {
  const { rive, RiveComponent } = useRive({
    src: "/rive/quantum_bg.riv",
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
    artboard: ARTBOARD_NAME,
    layout: new Layout({ fit: Fit.Cover }),
  });

  const turbo = useStateMachineInput(rive, STATE_MACHINE_NAME, "turbo-switch-boolen", false);

  useEffect(() => {
    if (turbo) {
      turbo.value = isRolling;
    }
  }, [isRolling, turbo]);

  return (
    <Box {...props}>
      <RiveComponent style={{ width: "100%", height: "100%" }} />
    </Box>
  );
};
